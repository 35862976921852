import React from "react"
import {
  IconBlockStandardWrapper,
  IconBlockContainer,
  IconBlockHeading,
  IconBlockSubheading,
  IconContainer,
  IconItem,
} from "./styles/Standard.styled"

const IconBlockStandard = ({ component }) => {
  const { heading, icons, subheading,  noTopPadding, noBottomPadding } = component;

  return (
    <IconBlockStandardWrapper>
      <IconBlockContainer noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
        <IconBlockHeading>{heading}</IconBlockHeading>
        <IconBlockSubheading>{subheading?.subheading}</IconBlockSubheading>
        <IconContainer>
          {icons?.map((icon, index) => {
            return (
              <IconItem target="_blank" href={icon?.url} key={index}>
                <img style={{ maxWidth: '72px' }} src={icon?.primaryImage?.file?.url} alt={icon?.primaryImage?.description} />
              </IconItem>
            )
          })}
        </IconContainer>
      </IconBlockContainer>
    </IconBlockStandardWrapper>
  )
}

export default IconBlockStandard
// const IconBlockStandardWrapper = styled.div`
//   // font-family: 'Roboto';
//   // background: #f2f4f7;
  
//   .icon-block--container {
//     // max-width: 1200px;
//     // margin: 0 auto;
//     // padding: 96px 108px;
    
//     // .icon-block--heading {
//     //   font-weight: 700;
//     //   font-size: 38px;
//     //   line-height: 42px;
//     //   text-align: center;
//     //   color: #1B1B1B;
//     // }

//     // .icon-block--subheading {
//     //   font-weight: 400;
//     //   font-size: 22ppx;
//     //   line-height: 42px;
//     //   text-align: center;
//     //   color: #475467;
//     // }

//     .icon-block--icon-container {
//       // display: flex;
//       // align-items: center;
//       // justify-content: space-between;
//       // margin: 64px auto 0;
//       // max-width: 928px;

//       // .icon-block--icon-item {
//       //   display: flex;
//       //   align-items: center;
//       //   width: 64px;
//       //   height: 64px;

//       //   &:first-child {
//       //     margin-left: 0;
//       //   }

//       //   &:last-child {
//       //     margin-right: 0;
//       //   }

//       //   img {
//       //     width: 100%;
//       //     height: auto;
//       //   }
//       // }
//     }
//   }

//   @media (max-width: 992px) {
//     .icon-block--container {
//       // padding: 96px 80px;

//       // .icon-block--icon-container {
//       //   max-width: 772px;
//       //   flex-wrap: wrap;
//       // }
//     }
//   }

//   // @media (max-width: 768px) {
//     // .icon-block--container {
//       // .icon-block--icon-container {
//         // .icon-block--icon-item {
//         //   &:nth-child(n+6) {
//         //     display: none; 
//         //   }
//         // }
//   //     }
//   //   }
//   // }

//   @media (max-width: 576px) {
//     .icon-block--container {
//       // padding: 96px 35px;

//       // .icon-block--heading {
//       //   font-size: 32px;
//       //   line-height: 42px;
//       // }

//       // .icon-block--icon-container {
//         // max-width: 418px;
//         // .icon-block--icon-item {
//         //   &:nth-child(n+5) {
//         //     display: none; 
//         //   }
//         // }
//       }
//     }
//   }
// `